<template>
  <el-col class="page" >
    <el-col class="title">地址管理</el-col>
    <el-col class="pane-container">
      <el-col class="pane">
        <el-button @click="drawer = true">添加地址</el-button>
      </el-col>
    </el-col>
    <el-col class="table-container">
      <el-table
          :data="tableData"
          border
          style="width: 100%"
          v-loading="loading">
        <el-table-column
            prop="addresseeName"
            label="收件人姓名">
        </el-table-column>
        <el-table-column
            prop="province"
            label="省">
        </el-table-column>
        <el-table-column
            prop="city"
            label="市">
        </el-table-column>
        <el-table-column
            prop="county"
            label="区">
        </el-table-column>
        <el-table-column
            prop="detailedAddress"
            label="详细地址">
        </el-table-column>
        <el-table-column
            prop="postalCode"
            label="邮编">
        </el-table-column>
        <el-table-column
            prop="phone"
            label="手机号">
        </el-table-column>
        <el-table-column
            prop="name"
            label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="openEdit(scope.row)">修改</el-button>
            <el-button size="mini" @click="syncAddressDel(scope.row)">删除</el-button>
          </template>

        </el-table-column>
      </el-table>
    </el-col>
    <el-col style="margin-top: 20px">

    </el-col>
    <el-col>
    <Page
        :total="page.total"
        :current="page.pageNo"
        :page-size="page.pageSize"
        @on-page-size-change="onPageSizeChange"
        @on-change="changePage"
        show-total show-elevator style="font-size: 14px"></Page>
    </el-col>
    <el-drawer
        title="增加地址"
        :visible.sync="drawer"
        direction="rtl"
        size="550px"
        class="drawer">
      <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
        <el-form-item
            label="收件人"
            prop="addresseeName"
            :rules="[
           { required: true, message: '收件人不能为空'},
          ]">
          <el-input type="addressee" v-model.number="numberValidateForm.addresseeName" autocomplete="off" placeholder="请输入收件人姓名"></el-input>
        </el-form-item>
        <el-form-item
            label="省/市/区"
            prop="address"
            :rules="[
           { required: true, message: '城市不能为空'}
          ]">
          <el-cascader
              ref="cascaderAdd"
              :data="options"
              @change="cascaderHandleChange"
              :options="options"
              placeholder="请选择城市"
          ></el-cascader>
        </el-form-item>
        <el-form-item
            label="详细地址"
            prop="detailedAddress"
            :rules="[
           { required: true, message: '详细地址不能为空'}
          ]">
          <el-input type="describeAddress" v-model.number="numberValidateForm.detailedAddress"
                    autocomplete="off" placeholder="请输入收件人详细地址"></el-input>
        </el-form-item>
        <el-form-item
            label="邮政编码"
            prop="postalCode"
            :rules="rules.postalCode">
          <el-input type="postalCode" v-model.number="numberValidateForm.postalCode" autocomplete="off" placeholder="请输入收件地址邮政编码"></el-input>
        </el-form-item>
        <el-form-item
            label="手机号码"
            prop="phone"
            :rules="rules.phone">
          <el-input type="phone" v-model.number="numberValidateForm.phone" autocomplete="off" placeholder="请输入收件人手机号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('numberValidateForm')">提交</el-button>
          <el-button @click="resetForm('numberValidateForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <el-drawer
        title="修改地址"
        :visible.sync="editDrawer"
        direction="rtl"
        size="550px"
        class="drawer">
      <el-form :model="editNumberValidateForm" ref="editNumberValidateForm" label-width="100px" class="demo-ruleForm">
        <el-form-item
            label="收件人"
            prop="addresseeName"
            :rules="[
           { required: true, message: '收件人不能为空'},
          ]">
          <el-input type="addressee" v-model.number="editNumberValidateForm.addresseeName" autocomplete="off" placeholder="请输入收件人姓名"></el-input>
        </el-form-item>
        <el-form-item
            label="省/市/区"
            prop="address"
            :rules="[
           { required: true, message: '城市不能为空'}
          ]">
          <el-cascader
              ref="cascaderEdit"
              :data="options"
              :props="{label:'label',value:'label',children:'children' }"
              @change="editCascaderHandleChange"
              :value="editCascader"
              :options="options"
              placeholder="请选择城市"
          ></el-cascader>
        </el-form-item>
        <el-form-item
            label="详细地址"
            prop="detailedAddress"
            :rules="[
           { required: true, message: '详细地址不能为空'}
          ]">
          <el-input type="describeAddress" v-model.number="editNumberValidateForm.detailedAddress"
                    autocomplete="off" placeholder="请输入收件人详细地址"></el-input>
        </el-form-item>
        <el-form-item
            label="邮政编码"
            prop="postalCode"
            :rules="rules.postalCodeEdit">
          <el-input v-model.number="editNumberValidateForm.postalCode" autocomplete="off" placeholder="请输入收件地址邮政编码"></el-input>
        </el-form-item>
        <el-form-item
            label="手机号码"
            prop="phone"
            :rules="rules.phone">
          <el-input type="phone" v-model.number="editNumberValidateForm.phone" autocomplete="off" placeholder="请输入收件人手机号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="syncAddressUpdate()">提交</el-button>
          <el-button @click="cancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-col>
</template>

<script>
import iView from 'iview'    //引入ivew库
import 'iview/dist/styles/iview.css'    // 使用 CSS
import Vue from 'vue'
import {optionsData} from '../../../utils/china-area'
import addressApi from "../../../api/buyer/system/addressApi";

Vue.use(iView)
export default {
  name: "address",
  data() {
    return {
      loading: true,
      total: 0,
      active: 1,
      drawer: false,
      direction: 'rtl',
      numberValidateForm: {
        address:'',
        addresseeName:'',
        detailedAddress: '',
        postalCode: '',
        phone: '',
        province:'',
        city:'',
        county:''
      },
      editDrawer:false,
      editNumberValidateForm: {
        address:'',
        addresseeName:'',
        detailedAddress: '',
        postalCode: '',
        phone: '',
        province:'',
        city:'',
        county:''
      },
      editCascader:[],
      rules: {
        phone: [
          { required: true, message: '手机号码不能为空', trigger: 'change' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/
              console.log(passwordreg.test(value))
              if (!passwordreg.test(value)) {
                callback(
                    new Error(
                        '手机号码格式不正确'
                    )
                )
              } else {
                callback()
              }
            }
          }
        ],
        postalCode: [
          { required: true, message: '邮政编码不能为空', trigger: 'change'},
          { type: 'integer', message: '邮政编码格式不正确', trigger: 'change'},
          {
            trigger: 'change',
            validator : (rule, value, callback) => {
              if(!/^[1-9]\d{5}$/.test(value)) {
                return callback(new Error("邮政编码格式不正确。"));
              }
              callback();
            }
          }
        ],
        postalCodeEdit: [
          { required: true, message: '邮政编码不能为空', trigger: 'change'},
          {
            trigger: 'change',
            validator : (rule, value, callback) => {
              if(!/^[1-9]\d{5}$/.test(value)) {
                return callback(new Error("邮政编码格式不正确。"));
              }
              callback();
            }
          }
        ]
      },
      tableData: [],
      options: optionsData(),
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 3
      },
    }
  },
  methods: {
    onPageSizeChange(val) {
      this.page.pageSize = val;
      this.getAddressList();
    },
    changePage(val) {
      this.page.pageNo = val;
      this.getAddressList();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.paneActive()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 关闭编辑弹窗
    cancel() {
      this.editDrawer = false;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    paneActive() {
      //this.active = id;
      this.syncAddressAdd();
    },
    getAddressList(){
      this.timer = setTimeout(()=>{   //设置延迟执行
        this.loading = false
      },400)
      this.tableData = [];
      let param = {pageNo: this.page.pageNo, pageSize: this.page.pageSize};
      /*if(this.date){
        var regEx = new RegExp("\\-","gi");
        let dependedVal=this.date.replace(regEx,"/");
        var dependedDate2 = new Date(dependedVal);
        param.createTime = dependedDate2
      }*/
      addressApi.getAddressList(param).then((res) => {

        if(res.data) {
          this.tableData = res.data.records;
          this.page.total = res.data.total
        }
      })
    },
    syncAddressAdd(){
      //{"province":"河北省","city":"秦皇岛市","county":"北戴河区","detailedAddress":"122121","postalCode":"121212","addresseeName":"123","phone":"13118806615"}
      /*addresseeName: 123
      city: (3) ['河北省', '秦皇岛市', '北戴河区', __ob__: Observer]
      detailedAddress: "阿萨大大"
      phone: 13118806616
      postalCode: 516400*/
      console.log(this.numberValidateForm)
      let assign1 = Object.assign({},this.numberValidateForm);
      console.log(assign1)
      addressApi.syncAddressAdd(assign1).then((res)=>{
        if (res.code == 200){
          this.editCascader = []
          this.$refs['cascaderAdd'].$refs.panel.clearCheckedNodes()
          this.$refs['cascaderAdd'].$refs.panel.activePath = []
          this.resetForm('numberValidateForm');
          this.$message.success('新建地址成功')
          this.drawer = false;
          this.getAddressList();
        }else {
          this.$message.error('新建地址失败')
        }
      })
    },
    syncAddressUpdate(){
      //{"addressId":"50d50413-679d-4161-811a-ee20f2f01b5f","province":"台湾省","city":"/","county":"/","detailedAddress":"阿萨德发","postalCode":"888888","addresseeName":"林志颖1","phone":"13456489797"}
      this.$refs['editNumberValidateForm'].validate((valid) => {
        if (valid) {
          //this.paneActive()
          console.log(this.editNumberValidateForm)
          let assign1 = Object.assign({},this.editNumberValidateForm);
          console.log(assign1)
          addressApi.syncAddressUpdate(assign1).then((res)=>{
            if (res.code == 200){
              this.editDrawer = false;
              this.$message.success('编辑地址成功')
              this.getAddressList();
            }else {
              this.$message.error('编辑地址失败')
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    syncAddressDel(row){
      let arr = [];
      arr.push(row.addressId)
      this.$confirm('此操作将删除该地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(arr)
        addressApi.syncAddressDel(arr).then((res)=>{
          if (res.code == 200){
            this.$message({
              type: 'success',
              message: '删除地址成功!'
            });
            this.getAddressList();
          }else {
            this.$message({
              type: 'success',
              message: '删除地址失败!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    cascaderHandleChange(row){
      if(row){
        if(row[0]){
          this.numberValidateForm.province = row[0]
        }
        if(row[1]){
          this.numberValidateForm.city = row[1]
        }
        if(row[2]){
          this.numberValidateForm.county = row[2]
        }
        this.numberValidateForm.address = row
      }
      console.log(this.numberValidateForm)
    },
    openEdit(row){
      this.editDrawer = true;
      this.$nextTick(()=>{
        console.log(row)
        this.editCascader = [];

        this.resetForm('editNumberValidateForm');
        this.editNumberValidateForm.addresseeName = row.addresseeName
        this.editNumberValidateForm.detailedAddress = row.detailedAddress
        this.editNumberValidateForm.postalCode = row.postalCode
        this.editNumberValidateForm.phone = row.phone
        this.editNumberValidateForm.province = row.province
        this.editNumberValidateForm.city = row.city
        this.editNumberValidateForm.county = row.county
        this.editNumberValidateForm.addressId = row.addressId
        this.editNumberValidateForm.address = row.province
        let list = [];
        list.push(row.province)
        list.push(row.city)
        list.push(row.county)
        console.log(444)
        console.log(list)
        this.editCascader=list
      })

      //this.editCascader= list2
      //'河北省', '唐山市', '路北区'
    },
    editCascaderHandleChange(row){
      if(row){
        if(row[0]){
          this.editNumberValidateForm.province = row[0]
        }
        if(row[1]){
          this.editNumberValidateForm.city = row[1]
        }
        if(row[2]){
          this.editNumberValidateForm.county = row[2]
        }
        this.editNumberValidateForm.address = row
      }
      let list = [];
      list.push(row[0])
      list.push(row[1])
      list.push(row[2])
      console.log(list)
      this.editCascader=list
    }
  },
  created() {
    // document.body.style.overflow='hidden'
    this.getAddressList()
  },
  mounted() {
    //this.getAddressList()
  }
}
</script>

<style lang="less" scoped>
.page {
  margin: 20px 35px;
  width: 97%;

.title {
color: #F56E65;
font-size: 24px;
letter-spacing: 1px;
margin-bottom: 40px;
}

.pane-container {
height: 34px;

.pane {
width: 48px;
height: 16px;
font-size: 16px;
margin-right: 40px;
color: rgba(65, 65, 65, 1);

/deep/ .el-button {
  color: #fff;
  background-color: #ed4014;
  border-color: #ed4014;
  height: 32px;
  width: 88px;
  padding: 8px 16px;
}

/deep/ .el-button:hover {
  color: #fff;
  background-color: #ee5630;
  border-color: #ee5630;
}
}

.pane:active {
color: #F56E65;
}

.pane:hover {
color: #F56E65;
}

.pane-active {
color: #F56E65;
}

}

.table-container {
margin-top: 20px;

/deep/ .el-table .cell, .el-table--border .el-table__cell:first-child .cell {
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

/deep/ .el-table thead {
color: #515A6E;
}
}

/deep/ .ivu-page {
float: right;
margin-right: 143px;
}

/deep/ .el-drawer__header {
height: 52px;
line-height: 20px;
font-size: 16px;
color: #17233d;
font-weight: 500;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

}

/deep/ .el-drawer__container {
margin-top: 58px;
}

.drawer {
/deep/ .el-form-item__label {
text-align: left;
margin-left: 20px;
width: 79px !important;
}

/deep/ .el-form-item__content {
margin-left: 100px !important;
width: 421px;
}

/deep/ .el-cascader {
width: 421px;
}
/deep/ .el-button--primary {
width: 90px;
height: 32px;
color: #fff;
background-color: #ed4014;
border-color: #ed4014;
padding-top: 9px;
}
/deep/ .el-button+.el-button {
margin-left: 35px;
width: 90px;
height: 32px;
padding-top: 9px;
}


}
}
</style>
