import {get,post} from "@/utils/axios"
// 查询地址
const getAddressList = p => get('/api-seller/address/getAddressList', p);
// 新增地址
const syncAddressAdd = p => post('/api-seller/address/syncAddressAdd', p);
// 编辑地址 syncAddressUpdate
const syncAddressUpdate = p => post('/api-seller/address/syncAddressUpdate', p);

const syncAddressDel = p => post('/api-seller/address/syncAddressDel', p);

export default {
    getAddressList,
    syncAddressAdd,
    syncAddressUpdate,
    syncAddressDel
}
